import React, { Component, useState, useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { Skeleton } from 'primereact/skeleton';
import { ProgressSpinner } from 'primereact/progressspinner';

import axios from "axios";
import { 
    CANDIDATES_COUNT_API_URL, 
    JOBS_WITH_TARGET_CAMPAIGNS_ACTIVE_COUNT_API_URL, 
    JOBS_WITH_TARGET_CAMPAIGNS_COMPLETED_THIS_MONTH_COUNT_API_URL, 
    JOBS_WITH_TARGET_CAMPAIGNS_STARTED_TODAY_COUNT_API_URL,
    CAMPAIGN_SUCCESS_COMPLETED_THIS_MONTH_API_URL,
 } from "../../constants";

import SalesHeader from "./Header";
import CampaignsHeader from "./Header";
import DashboardBox from "../Global/Box";


const CandidateAcquisitionDashboard = (props) => {
    const token = localStorage.getItem('accessToken');
    let [activeCampaignCount, setActiveCampaignCount] = useState();
    let [campaignsStartedToday, setCampaignsStartedToday] = useState();
    let [campaignsCompletedThisMonth, setCampaignsCompletedThisMonth] = useState();
    let [campaignSuccessCompletedThisMonth, setCampaignSuccessCompletedThisMonth] = useState();
    let [candidateCountToday, setCandidateCountToday] = useState();

    const getActiveCampaignCount = () => {
        axios.get(JOBS_WITH_TARGET_CAMPAIGNS_ACTIVE_COUNT_API_URL, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then(res => setActiveCampaignCount(res.data));
    };

    const getCampaignsStartedToday = () => {
        axios.get(JOBS_WITH_TARGET_CAMPAIGNS_STARTED_TODAY_COUNT_API_URL, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then(res => setCampaignsStartedToday(res.data));
    };

    const getCampaignsCompletedThisMonth = () => {
        axios.get(JOBS_WITH_TARGET_CAMPAIGNS_COMPLETED_THIS_MONTH_COUNT_API_URL, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then(res => setCampaignsCompletedThisMonth(res.data));
    };

    const getCampaignSuccessCompletedThisMonth = () => {
        axios.get(CAMPAIGN_SUCCESS_COMPLETED_THIS_MONTH_API_URL, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then(res => setCampaignSuccessCompletedThisMonth(res.data));
    };

    const getCandidateCountToday = () => {
        axios.get(CANDIDATES_COUNT_API_URL, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then(res => setCandidateCountToday(res.data));
    };

    useEffect(() => getActiveCampaignCount(), []);
    useEffect(() => getCampaignsStartedToday(), []);
    useEffect(() => getCampaignsCompletedThisMonth(), []);
    useEffect(() => getCampaignSuccessCompletedThisMonth(), []);
    useEffect(() => getCandidateCountToday(), []);
    
    return (
      <Container className="Main MainAdminFullWidth MainWithHero"> 
        <div className="Centralizer">
        <CampaignsHeader
            current_user={props.current_user}
        />
        <Row>
            <Col>
                <h2>
                    Campaign Perfomance Dashboard
                </h2>
            </Col>
        </Row>
        <Row>
            <Col md="4" sm="12">
                <DashboardBox
                    highlight={true}
                    title={'Active Campaigns'}
                    value={activeCampaignCount}
                    description={'Jobs with currently active campaigns'}
                    link={'/campaigns/performance'}
                    link_text={'Continue'}
                />
            </Col>
            <Col md="4" sm="12">
                <DashboardBox
                    highlight={false}
                    title={'Campaigns Started Today'}
                    value={campaignsStartedToday}
                    description={'Count of campaigns started today'}
                    link={'/campaigns/campaigns'}
                    link_text={'Continue'}
                />
            </Col>
            <Col md="4" sm="12">
                <DashboardBox
                    highlight={false}
                    title={'Candidates Today'}
                    value={candidateCountToday}
                    description={'Total candidates acquired today'}
                    link={'/campaigns/candidates'}
                    link_text={'Continue'}
                />
            </Col>
            <Col md="4" sm="12">
                <div className="Box">
                    <h4>Campaign Perfomance</h4>
                    <p>Knowledge base for our combined and built up understanding of Campaign Performance</p>
                    <a href="https://jobilla.slite.com/app/docs/BkyZqQTMp9ENcD" target="_blank" className="btn PurpleGradientOutline" >
                        Open Slite
                    </a>
                </div>
            </Col>


        </Row>
        </div>
      </Container>
    );
}

export default CandidateAcquisitionDashboard;
