import React, { Component, useState, useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";

import axios from "axios";
import { 
    JOBS_WITH_TARGET_CAMPAIGNS_ACTIVE_COUNT_API_URL, 
    JOBS_WITH_TARGET_CAMPAIGNS_STARTED_TODAY_COUNT_API_URL,
    TARGET_CAMPAIGNS_API_URL,
} from "../../constants";

import CampaignsHeader from "./Header";
import CampaignList from "./CampaignList";
import { ProgressSpinner } from "primereact/progressspinner";
import DashboardBox from "../Global/Box";


const Campaigns = (props) => {
    const token = localStorage.getItem('accessToken');
    let [activeCampaignCount, setActiveCampaignCount] = useState();
    let [campaigns, setCampaigns] = useState([]);
    let [campaignSearch, setCampaignSearch] = useState('');
    let [campaignsStartedToday, setCampaignsStartedToday] = useState();
    let [page, setPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [debouncedTerm, setDebouncedTerm] = useState(campaignSearch);

    const handleCampaignSearch = (event) => {
        setCampaignSearch(event.target.value);
        getFilteredCampaigns(event.target.value);
    };

    const handlePagination = (page) => {
        setPage(page);
        getFilteredCampaigns(campaignSearch);
    };

    const getActiveCampaignCount = () => {
        axios.get(JOBS_WITH_TARGET_CAMPAIGNS_ACTIVE_COUNT_API_URL, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then(res => setActiveCampaignCount(res.data));
    };

    const getCampaignsStartedToday = () => {
        axios.get(JOBS_WITH_TARGET_CAMPAIGNS_STARTED_TODAY_COUNT_API_URL, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then(res => setCampaignsStartedToday(res.data));
    };

    const getCampaigns = () => {
        axios.get(TARGET_CAMPAIGNS_API_URL, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then(res => setCampaigns(res.data));
    };

    const getFilteredCampaigns = (campaignSearch) => {
        if (campaignSearch?.length >= 2) {
            axios.get(TARGET_CAMPAIGNS_API_URL + '?s=' + campaignSearch + '&page=' + page, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }).then(res => {
                setCampaigns(res.data);
                setIsLoading(false);
            })
        } else {
            axios.get(TARGET_CAMPAIGNS_API_URL, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }).then(res => setCampaigns(res.data));    
        }

    };

    // Update debouncedTerm after a delay whenever searchTerm changes
    useEffect(() => {
        const handler = setTimeout(() => {
        setDebouncedTerm(campaignSearch);
        }, 500); // Wait 500ms after the user stops typing

        // Cleanup the timeout if the user types within the 500ms
        return () => {
        clearTimeout(handler);
        };
    }, [campaignSearch]);

    // This effect triggers the search whenever debouncedTerm changes
    useEffect(() => {
        if (debouncedTerm) {
            getFilteredCampaigns(debouncedTerm);
        }
    }, [debouncedTerm]);

    useEffect(() => getActiveCampaignCount(), []);
    useEffect(() => getCampaignsStartedToday(), []);
    useEffect(() => getFilteredCampaigns(), []);

    return (
      <Container className="Main MainAdminFullWidth MainWithHero"> 
        <div className="Centralizer">
            <CampaignsHeader
                current_user={props.current_user}
            />
            <Row>
                <Col>
                    <h2>
                        Campaigns
                    </h2>
                </Col>
            </Row>
            <Row>
                <Col md="4" sm="12">
                    <DashboardBox
                        highlight={true}
                        title={'Active Campaigns'}
                        value={activeCampaignCount}
                        description={'Jobs with currently active campaigns'}
                    />
                </Col>
                <Col md="4" sm="12">
                    <DashboardBox
                        highlight={false}
                        title={'Campaigns Started Today'}
                        value={campaignsStartedToday}
                        description={'Count of campaigns started today'}
                    />
                </Col>
            </Row>
        </div>
        <div className="Content">
            <Row>
                <Col sm="2">
                    <div className="RecruitmentSearch AdminFilter">
                        <label className="FilterLabel">Search</label>
                        <input className="TextInput" placeholder="Search" value={campaignSearch} onChange={handleCampaignSearch}/>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <CampaignList 
                        current_user={props.current_user}
                        campaigns={campaigns}
                        isLoading={isLoading}
                    />
                </Col>
            </Row>
        </div>
      </Container>
    );
}

export default Campaigns;
