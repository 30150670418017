import { faDashboard } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component, useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import { NumericFormat } from 'react-number-format';

const FunnelConversion = (props) => {

    let posthogFunnelData = props.posthogFunnelData;

    return (
        <>
        <Row>
            <Col>
                Data from: {posthogFunnelData?.date_from} - {posthogFunnelData?.date_to}
            </Col>
        </Row>
        <Row>
            <Col>
                <h3>Ad Views</h3>
            </Col>
            <Col>
                <h3>Ad Clicks</h3>
            </Col>
            <Col>
                <h3>Welcome</h3>
                {posthogFunnelData?.welcome?.unique_visitors}
                <hr/>
                <NumericFormat
                    value={posthogFunnelData?.welcome?.step_conversion * 100}
                    displayType={'text'}
                    thousandSeparator={true}
                    decimalScale={1}
                /> %
            </Col>
            <Col>
                <h3>Respond</h3>
                {posthogFunnelData?.respond?.unique_visitors}
                <hr/>
                <NumericFormat
                    value={posthogFunnelData?.respond?.step_conversion * 100}
                    displayType={'text'}
                    thousandSeparator={true}
                    decimalScale={1}
                /> %
            </Col>
            <Col>
                <h3>Apply</h3>
                {posthogFunnelData?.apply?.unique_visitors}
                <hr/>
                <NumericFormat
                    value={posthogFunnelData?.apply?.step_conversion * 100}
                    displayType={'text'}
                    thousandSeparator={true}
                    decimalScale={1}
                /> %
            </Col>
            <Col>
                <h3>Candidates</h3>
            </Col>
        </Row>
        </>
);
}

export default FunnelConversion;
