import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Pagination, PaginationItem, PaginationLink, Table } from "reactstrap";
import Moment from 'react-moment';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBriefcase, faExternalLink, faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { Skeleton } from 'primereact/skeleton';

const CampaignList = (props) => {
    let campaigns = props.campaigns;
    let isLoading = props.isLoading;

    return (
    <>
      <div className="TableContainer">
        <Table className="WhiteTable AdminAnalysisTable">
            <thead>
            <tr>
                <th>TAS</th>
                <th>Campaign Name</th>
                <th>Company</th>
                <th>Candidates</th>
                <th>Starts</th>
                <th>Ends</th>
            </tr>
            </thead>
            <tbody>
            {!campaigns || campaigns?.length <= 0 || isLoading ? (
                <tr>
                    <td colSpan="4">
                        {!campaigns <= 0 ? (
                                <Skeleton className="mb-2"></Skeleton>                    
                            ) : (
                                <>
                                <b>Ops, no campaigns here yet</b>
                                </>
                            )}                        
                    </td>
                </tr>
            ) : (
                campaigns?.map(campaign => (
                <tr key={campaign.id}>
                    <td width="5%">
                        <a href={"https://tas.app.jobilla.com/c/" + campaign.company_id + "/jobs/ " + campaign.job} target="_blank">
                           <FontAwesomeIcon icon={faExternalLinkAlt } />
                        </a>
                    </td>
                    <td width="30%">
                        {props.current_user?.user == 1 ? (
                        <Link to={"/campaigns/campaigns/campaign/" + campaign.job}>
                            {campaign.job_name}
                        </Link>
                        ) : (
                        <>
                            {campaign.job_name}
                        </>
                        )}
                    </td>
                    <td width="25%">
                        {campaign.company_name}
                    </td>
                    <td>
                        {campaign.candidate_count}
                    </td>
                    <td>
                        <Moment format="DD/MM/YYYY" date={campaign.starts_at} />
                    </td>
                    <td>
                        <Moment format="DD/MM/YYYY" date={campaign.ends_at} />
                    </td>
                </tr>
                ))
            )}
            </tbody>
        </Table>
      </div>
    </>
    );
}

export default CampaignList;
