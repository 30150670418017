import React, { Component, useState, useEffect } from "react";
import { Col, Container, Row, Table } from "reactstrap";
import { withRouter } from "react-router";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarker, faBuilding } from '@fortawesome/free-solid-svg-icons'
import { Link } from "react-router-dom";
import Moment from 'react-moment';

import { Knob } from 'primereact/knob';
import { ProgressBar } from 'primereact/progressbar';

import axios from "axios";
import { POSTHOG_UNIQUE_VISITORS_API_URL } from "../../constants";
import TalentInsightsHeader from "./Header";
import CampaignsHeader from "./Header";
import FunnelConversion from "./Campaign/FunnelConversion";

const Campaign = (props) => {
    const token = localStorage.getItem('accessToken');
    let [campaign, setCampaign] = useState();
    let [posthogFunnelData, setPosthogFunnelData] = useState({});

    let url = window.location.pathname
    let url_components = url.split('/')
    let pathway_id = url_components[3]

    const getPosthogFunnelData = () => {
        axios.get(POSTHOG_UNIQUE_VISITORS_API_URL, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            setPosthogFunnelData(response.data)
        });
    };

    // const getFilteredGroups = () => {
    //     let complete_url = GROUPS_API_URL + '?pathway=' + pathway_id
    //     axios.get(complete_url).then(res => setGroups(res.data));    
    // };


    useEffect(() => getPosthogFunnelData(), []);
    console.log(posthogFunnelData)
    // useEffect(() => getFilteredGroups(), []);

    return (
        <Container className="Main MainAdminFullWidth MainWithHero">
            <div className="Centralizer">
                <CampaignsHeader 
                    current_user={props.current_user}
                />
                <Row>
                    <Col>
                        <div className="Box DarkPurple TalentInsightsBox">
                            <h2>
                                Campaign: {posthogFunnelData?.uuid}
                            </h2>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="Content">
                <Row>
                    <Col sm="6">
                        <div className="Box">
                            <h4>Campaign Basics</h4>
                            <a className="btn btn-primary" href={posthogFunnelData?.url + '?reference'} target="_blank">Visit Funnel</a>
                            <table className="table">
                                <tbody>
                                    <tr>
                                        <td width="50%">
                                            Career Cluster
                                        </td>
                                        <td>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="50%">
                                            Career Pathway
                                        </td>
                                        <td>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="50%">
                                            Job Group (ESCO)
                                        </td>
                                        <td>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </Col>
                    <Col sm="4">
                        <div className="Box">
                            <h4>Sources</h4>
                        </div>
                    </Col>
                </Row>
            </div>
            <br/>
            <div className="ContentWhite">
                <Row>
                    <Col>
                        <h4>Meta</h4>
                    </Col>
                </Row>
            </div>
            <br/>
            <div className="ContentWhite">
                <Row>
                    <Col>
                        <h4>Conversion Funnel</h4>
                    </Col>
                </Row>
                <FunnelConversion
                    posthogFunnelData={posthogFunnelData}
                />
          </div>
        </Container>
    );
};
export default Campaign;