import { ProgressSpinner } from 'primereact/progressspinner';
import React from 'react';
import { Link } from 'react-router-dom';

const DashboardBox = (props) => {
    return(
        <div className={props.highlight ? 'Box DarkPurple' : 'Box'}>
            <h4>{props.title}</h4>
            <span className="BoxHighlight">
                {props.value || props.value === 0 ? props.value.toLocaleString('de-DE') : (
                    <ProgressSpinner style={{width: '36px', height: '36px', color: '#eee'}} strokeWidth="6" animationDuration="1.5s" />
                )}
                {props.percentage ? ' %' : ''}
                {props.currency ? ' €' : ''}
            </span>
            <p>{props.description}</p>
            {props.link ? (
            <Link to={props.link_url} className="btn PurpleGradient" >
                {props.link_text}
            </Link>
            ) : ''}
        </div>
    );
}

export default DashboardBox;
