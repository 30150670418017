const BASE_URL = process.env.REACT_APP_LOCAL === 'true' ? 'http://localhost:5555/api' : 'https://jobilla-brains.rede.fi/api';
const DATABASE_BASE_URL = 'https://jobilla-brains.rede.fi/api';

// General
export const USERS_API_URL =  BASE_URL + "/userprofiles/";
export const CURRENT_USER_API_URL =  BASE_URL + "/userprofiles/current";

export const USER_COMPANIES_API_URL =  BASE_URL + "/user-companies/";
export const COMPANY_USERS_API_URL =  BASE_URL + "/company-users/";

export const COMPANIES_API_URL =  BASE_URL + "/companies/";
export const COMPANY_COUNT_API_URL =  BASE_URL + "/companies/count";
export const CURRENT_COMPANY_API_URL =  BASE_URL + "/companies/current";
export const DEPARTMENTS_API_URL =  BASE_URL + "/departments/";

export const LANGUAGES_API_URL =  BASE_URL + "/languages/";

// Recruitment Software
export const RECRUITMENTS_API_URL = BASE_URL + "/recruitments/";
export const LATEST_RECRUITMENT_API_URL = BASE_URL + "/latest-recruitment/";

export const PIPELINE_STAGES_API_URL = "/pipeline-stages/";
export const PIPELINE_STAGE_CANDIDATES_API_URL = "/pipeline-stage-candidates/";
export const CANDIDATE_PROFILES_API_URL = BASE_URL + "/candidate-profiles/";

// Candidate Processing
export const CANDIDATE_LABELS_API_URL = BASE_URL + "/candidate-labels/";
export const CANDIDATE_COMMENTS_API_URL = BASE_URL + "/candidate-comments/";
export const CANDIDATE_EVALUATIONS_API_URL = BASE_URL + "/candidate-evaluations/";
export const CANDIDATE_EVENTS_API_URL = BASE_URL + "/candidate-events/";
export const CANDIDATE_EMAILS_API_URL = BASE_URL + "/candidate-emails/";
export const CANDIDATE_REACTIONS_API_URL = BASE_URL + "/candidate-reactions/";
export const CANDIDATES_API_URL = BASE_URL + "/candidates/";
export const CANDIDATE_COUNT_API_URL = BASE_URL + "/candidates/count";
export const HANDLE_CANDIDATE_COUNT_API_URL = BASE_URL + "/candidates/handle/count";

// Todos
export const TODOS_API_URL = BASE_URL + "/todos/";

// Analytics
export const ANALYTICS_API_URL = BASE_URL + "/analytics/";
export const ANALYTICS_RECRUITER_API_URL = BASE_URL + "/analytics/recruiter/";

// Explorer
export const OCCUPATIONS_API_URL = BASE_URL + "/esco-occupations/";
export const GROUPS_API_URL = BASE_URL + "/esco-groups/";
export const SKILLS_API_URL = BASE_URL + "/esco-skills/";
export const INDUSTRIES_API_URL = BASE_URL + "/industries/";
export const INDUSTRY_ROLES_API_URL = BASE_URL + "/industry-roles/";
export const ADMIN_INDUSTRY_ROLES_API_URL = BASE_URL + "/admin/industry-roles/";
export const ADMIN_ROLE_GROUPS_API_URL = BASE_URL + "/admin/role-groups/";

export const JOB_SAMPLES_API_URL = BASE_URL + "/job-samples/";


// TALENT INSIGHTS
export const PATHWAYS_API_URL = BASE_URL + "/career-pathways/";

// LIGHTCAST
export const CLUSTERS_API_URL = BASE_URL + "/lightcast-attributes/categories/";
export const LIGHTCAST_ATTRIBUTES_CATEGORIES_API_URL = BASE_URL + "/lightcast-attributes/categories/";
export const LIGHTCAST_ATTRIBUTES_SKILLS_API_URL = BASE_URL + "/lightcast-attributes/skills/";
export const LIGHTCAST_ATTRIBUTES_SKILL_CERTIFICATIONS_API_URL = BASE_URL + "/lightcast-attributes/skill-certifications/";
export const LIGHTCAST_ATTRIBUTES_TITLES_API_URL = BASE_URL + "/lightcast-attributes/titles/";

export const LIGHTCAST_RECRUITMENT_SKILLS_API_URL = BASE_URL + "/lightcast-recruitment-skills/";

// Candidate Funnel
export const QUESTIONS_API_URL = BASE_URL + "/questions/";
export const FUNNELS_API_URL = BASE_URL + "/funnels/";
export const FUNNEL_AD_IMAGES_API_URL = BASE_URL + "/funnel-ad-images/";
export const FUNNEL_LANDINGS_API_URL = BASE_URL + "/funnel-landings/";
export const CANDIDATE_ADDITIONAL_INFO_API_URL = BASE_URL + "/funnel-additional-info/";
export const SAVE_FUNNEL_ANALYTICS_EVENTS_API_URL = BASE_URL + "/funnel-analytics-save/";


export const GENERATE_TRIAL_CANDIDATES_URL = BASE_URL + "/generate-trial-candidates/";

// Orders (for buyer)
export const ORDERS_API_URL = BASE_URL + "/orders/";
export const ORDER_ITEMS_LIST_API_URL = BASE_URL + "/order-items/";
export const ORDER_ITEMS_AVAILABLE_API_URL = BASE_URL + "/order-items/status/available";
export const ORDER_ITEMS_WIP_API_URL = BASE_URL + "/order-items/status/wip";
export const ORDER_ITEMS_ACTIVE_API_URL = BASE_URL + "/order-items/status/active";
export const ORDER_ITEMS_USED_API_URL = BASE_URL + "/order-items/status/completed";
export const ORDER_ITEMS_AVAILABLE_COUNT_API_URL = BASE_URL + "/order-item-actions/count";
export const ORDER_ITEMS_CONSUME_API_URL = BASE_URL + "/order-item-actions/consume/";
export const ORDER_ITEM_STATUSES_API_URL = BASE_URL + "/order-item-statuses/";
export const CAMPAIGN_REQUEST_API_URL = BASE_URL + "/campaigns/request/";

// Orders (for admin)
export const ADMIN_CANDIDATES_API_URL = BASE_URL + "/admin/candidates/";
export const ADMIN_ORDERS_API_URL = BASE_URL + "/admin/orders/";
export const ADMIN_ORDER_ITEMS_API_URL = BASE_URL + "/admin/order-items/";
export const ADMIN_ORDER_ITEMS_BY_ORDER_API_URL = BASE_URL + "/admin/order-items/by-order/";
export const ADMIN_ORDER_ITEMS_DATA_API_URL = BASE_URL + "/admin/order-items-data/";
export const ADMIN_INVOICES_API_URL = BASE_URL + "/admin/invoices/";
export const ADMIN_INVOICES_BY_ORDER_API_URL = BASE_URL + "/admin/invoices/by-order/";
export const ADMIN_INVOICES_DATA_API_URL = BASE_URL + "/admin/invoices-data/";
export const ADMIN_RECRUITMENTS_API_URL = BASE_URL + "/admin/recruitments/";
export const ADMIN_RECRUITER_RECRUITMENTS_API_URL = BASE_URL + "/admin/recruitments/recruiter/";
export const ADMIN_NEW_RECRUITMENTS_API_URL = BASE_URL + "/admin/recruitments/new/";
export const ADMIN_CAMPAIGN_CONTEXTS_API_URL = BASE_URL + "/admin/campaign-contexts/";


// Targeting (for admin)
export const ADMIN_CAMPAIGNS_API_URL = BASE_URL + "/admin/campaigns/";
export const ADMIN_TARGETING_CITIES_API_URL = BASE_URL + "/admin/targeting-cities/";
export const ADMIN_TARGETING_INTERESTS_API_URL = BASE_URL + "/admin/targeting-interests/";
export const ADMIN_CITIES_API_URL = BASE_URL + "/admin/cities/";
export const ADMIN_COUNTRIES_API_URL = BASE_URL + "/admin/countries/";


// Admin Recruiter
export const ADMIN_DASHBOARD_API_URL = BASE_URL + "/admin/dashboard/";
export const ADMIN_RECRUITERS_API_URL = BASE_URL + "/admin/recruiters/";
export const ADMIN_BUSINESS_UNITS_API_URL = BASE_URL + "/admin/business-units/";
export const ADMIN_INTERNAL_USER_ROLES_API_URL = BASE_URL + "/admin/internal-user-roles/";
export const ADMIN_ATTRIBUTES_API_URL = BASE_URL + "/admin/attributes/";
export const ADMIN_QUESTIONS_API_URL = BASE_URL + "/admin/questions/";

// AI GENERATED
export const AI_GENERATE_QUESTIONS_API_URL = BASE_URL + "/questions/ai/generate/";
export const AI_GENERATE_JOB_AD_DESCRIPTIONS_API_URL = BASE_URL + "/job-descriptions/ai/generate/";
export const AI_GENERATE_EMAIL_DRAFT_API_URL = BASE_URL + "/email-drafts/ai/generate/";
export const AI_GENERATE_CALL_SCRIPT_API_URL = BASE_URL + "/call-scripts/ai/generate/";

// EXPORT
export const DOWNLOAD_CANDIDATES_API_URL = BASE_URL + "/export/candidates-in-excel/";


// GIANT
export const GIANT_API_CALL_URL = BASE_URL + "/giant/api/"
export const GIANT_EDUCATION_LEVEL_API_URL = BASE_URL + "/giant/education-level/"
export const GIANT_AGE_DISTRIBUTION_API_URL = BASE_URL + "/giant/age-distribution/"
export const GIANT_FEASIBILITIES_API_URL = BASE_URL + "/giant/feasibility/"
export const GIANT_GENDER_API_URL = BASE_URL + "/giant/gender/"
export const GIANT_JOB_MARKET_ACTIVITY_API_URL = BASE_URL + "/giant/job-market-activity/"
export const GIANT_SALARY_API_URL = BASE_URL + "/giant/salary/"
export const GIANT_WORK_EXPERIENCE_API_URL = BASE_URL + "/giant/work-experience/"
export const GIANT_TARGET_GROUP_SIZE_API_URL = BASE_URL + "/giant/target-group-size/"
export const GIANT_JOB_BENEFITS_API_URL = BASE_URL + "/giant/job-benefits/"
export const GIANT_PULL_FACTORS_API_URL = BASE_URL + "/giant/pull-factors/"
export const GIANT_SOURCING_PRESSURE_API_URL = BASE_URL + "/giant/sourcing-pressure/"
export const GIANT_JOB_CHANGES_API_URL = BASE_URL + "/giant/jobchanges/"
export const GIANT_SOCIALMEDIA_APPS_API_URL = BASE_URL + "/giant/socialmedia-apps/"
export const GIANT_ASPECTS_NEGOTIATION_API_URL = BASE_URL + "/giant/aspects-negotiation/"
export const GIANT_ASPECTS_APPLICATION_PROCESS_API_URL = BASE_URL + "/giant/aspects-application-process/"
export const GIANT_WILLINGNESS_TRAVEL_API_URL = BASE_URL + "/giant/willingness-travel/"


// Posthog
export const POSTHOG_UNIQUE_VISITORS_API_URL = BASE_URL + "/campaigns-api/posthog/unique-visitors/"


// AI Incubator
// UPDATE AIS TO DATABASE
export const AI_JOBS_API_URL = DATABASE_BASE_URL + "/ai-incubator-api/jobs/"
export const AI_TARGETING_INTERESTS_API_URL = DATABASE_BASE_URL + "/ai-incubator-api/targeting-interests/"
export const AI_WELCOME_CONTENT_API_URL = DATABASE_BASE_URL + "/ai-incubator-api/welcome-page/"
export const AI_QUESTIONS_API_URL = DATABASE_BASE_URL + "/ai-incubator-api/questions/"
export const AI_LOGS_API_URL = BASE_URL + "/ai-incubator-api/logs/count/"
export const AI_LOGS_COMPLETION_RATE_API_URL = BASE_URL + "/ai-incubator-api/logs/completion-rate/"
export const AI_LOGS_COMPLETION_TIME_API_URL = BASE_URL + "/ai-incubator-api/logs/completion-time/"
export const AI_JOB_LOGS_API_URL = BASE_URL + "/ai-incubator-api/logs/job-count/"


// JOBILLA DB CAMPAIGNS
export const TARGET_CAMPAIGNS_API_URL = DATABASE_BASE_URL + "/campaigns-api/target-campaigns/"
export const JOBS_WITH_TARGET_CAMPAIGNS_API_URL = DATABASE_BASE_URL + "/campaigns-api/jobs-with-target-campaigns/"
export const JOBS_WITH_TARGET_CAMPAIGNS_ACTIVE_COUNT_API_URL = DATABASE_BASE_URL + "/campaigns-api/jobs-with-target-campaigns/count-active/"
export const JOBS_WITH_TARGET_CAMPAIGNS_STARTED_TODAY_COUNT_API_URL = DATABASE_BASE_URL + "/campaigns-api/jobs-with-target-campaigns/count-today/"
export const JOBS_WITH_TARGET_CAMPAIGNS_COMPLETED_THIS_MONTH_COUNT_API_URL = DATABASE_BASE_URL + "/campaigns-api/jobs-with-target-campaigns/count-completed-month/"
export const CAMPAIGN_SUCCESS_COMPLETED_THIS_MONTH_API_URL = DATABASE_BASE_URL + "/campaigns-api/jobs-with-target-campaigns/campaign-success-completed-month/"


// JOBILLA DB CANDIDATES
export const CANDIDATES_DATA_API_URL = DATABASE_BASE_URL + "/campaigns-api/candidates/"
export const CANDIDATES_COUNT_API_URL = DATABASE_BASE_URL + "/campaigns-api/candidates/count/"

// JOBILLA DB GROWTH
export const ORDER_DATA_API_URL = DATABASE_BASE_URL + "/growth-api/orders/" 
export const ORDER_VALUE_MONTH_API_URL = DATABASE_BASE_URL + "/growth-api/orders/value-month/" 
export const ORDER_COUNT_MONTH_API_URL = DATABASE_BASE_URL + "/growth-api/orders/count-month/" 
export const ORDER_COUNT_TODAY_API_URL = DATABASE_BASE_URL + "/growth-api/orders/count-today/" 

export const ORDER_ITEMS_CONSUMED_VALUE_MONTH_API_URL = DATABASE_BASE_URL + "/growth-api/order-items/value-month/" 